@use "../../variables"as var;

.header {
  // z-index: 100;
  width: 100vw;
  position: relative;
  align-items: center;
  justify-content: space-between;
  // position: fixed;
  // top: 0;
  padding: 0 4rem;
  height: 4rem;
}

@media (max-width: var.$mobile-break-point) {
  .header {
    padding: 0 1rem;
  }
}