@use "../../variables" as var;
.walletButton {
  &.header {
    padding: 0px 16px;
    font-size: 14px;
    line-height: 18px;
    height: 36px;
  }
}

@media (max-width: var.$mobile-break-point) {
  .walletButton {
    &.header {
      padding: 5px;
      font-size: 14px;
    }
  }
}
