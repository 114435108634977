body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --header-height: 56px;
  --primary-color: #ff5c00;
  --primary-hover-color: #e87327;
}

@font-face {
  font-family: "Noi Grotesk Trial";
  src: url("./assets/fonts/NoiGroteskTrial/NoiGroteskTrial-Regular.ttf") format("truetype");
  /* 正常粗细 */
}

@font-face {
  font-family: "Noi Grotesk Trial-Bold";
  src: url("./assets/fonts/NoiGroteskTrial/NoiGroteskTrial-Bold.ttf") format("truetype");
  /* 粗体 */
}

body {
  font-family: "Noi Grotesk Trial", sans-serif !important;
  /* 使用您定义的字体，如果无法加载则使用备用字体 */
}

.container {
  display: flex;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  flex-wrap: wrap;
  // overflow: hidden;
}

.container-body {
  height: calc(100% - 4rem);
  width: 100%;
  box-sizing: border-box;
}


::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  /* color of the scroll thumb */
  border-radius: 4px;
  /* roundness of the scroll thumb */
  /*border: 1px solid #fff;*/
  /* creates padding around scroll thumb */
}