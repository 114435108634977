@use "../../variables"as var;

.token {
  // min-height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.6rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.tab {
  // width: 664px;
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  padding-bottom: 20px;
}

.content {
  flex: 1;
  box-sizing: border-box;
  overflow-y: auto;
}

.contentContainer {
  width: 100%;
  height: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;

  >div {
    width: 664px;
    margin: 0 auto;
  }
}

@media (max-width: var.$mobile-break-point) {
  .token {
    padding-top: 12px;
  }

  .tab {
    width: 100%;
  }

  .content {
    padding-top: 0px;
  }

  .contentContainer {
    >div {
      width: 100%;
    }
  }
}