@use "../../variables" as var;
.title {
  margin-top: 28px;
  margin-bottom: 16px;
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
}

.description {
  color: #ffffff99;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}

@media (max-width: var.$mobile-break-point) {
  .title {
    margin-top: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  .description {
    font-size: 14px;
    line-height: 21px;
  }
}
