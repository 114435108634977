@use "../../../variables"as var;

.card {
  padding: 40px;
  margin-bottom: 12px;
  border-radius: 12px;
}

.transaction {
  margin-top: 24px;
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: var.$mobile-break-point) {
  .card {
    padding: 16px;
    margin-bottom: 16px;
  }

  .transaction {
    margin-top: 20px;
    padding: 12px;
  }
}