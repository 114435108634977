@use "../../../../variables" as var;

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff4d;
  font-size: 14px;
}

@media (max-width: var.$mobile-break-point) {
  .top {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 6px;
  }
}
