@use "../../../variables" as var;
.transfer {
  padding: 38px 32px 40px;
  border-radius: 12px;
  gap: 32px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 18px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

.swap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.swapIcon {
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 10px;
  background-color: #323232;
}

.walletButton {
  font-size: 20px !important;
  width: 318px;
  height: 48px !important;
  margin: 32px auto 0;
}

@media (max-width: var.$mobile-break-point) {
  .transfer {
    padding: 26px 12px;
    gap: 28px;
  }

  .header {
    display: block;
  }

  .headerLeft {
    justify-content: space-between;
  }

  .swap {
    position: relative;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .swapIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #474747;
  }

  .walletButton {
    width: 100%;
    margin: 20px auto 0;
  }
}
